@import './typography.scss';
@import '../layout/body.scss';

//new changes 5/6/2021 by syafiq ali
.main-header-fix {
  position: fixed;
  width: calc(100% - 288px);
  top: 72px;
  z-index: 999;
  transition: width 0.2s;
  right: 24px;
}
.main-header-fix-fw {
  position: fixed;
  width: calc(100% - 48px);
  top: 72px;
  z-index: 999;
  transition: width 0.2s;
  right: 24px;
}
.fix-subheader {
  position: relative;
  z-index: 100;
}
.wraps-dynamic-content {
  position: absolute;
  width: calc(100% - 77px);
  right: 14px;
  height: 40px;
}
.flex {
  display: flex;
}

.mobile-header {
  &::before {
    @media screen and (min-width: 600px) {
      content: close-quote;
      background: #f5f5f5;
      width: 100%;
      height: 24px;
    }
  }
  // background-color: #ffffff !important;
  background: url(./../../images/header-bg.svg);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 130%;

  // background: linear-gradient(to right, #2f47ff, #2408ba);
  &.MuiAppBar-root {
    z-index: 2;
    @media screen and (min-width: 600px) {
      // position: unset;
      box-shadow: none;
      top: 64px;
      width: calc(100% - 288px);
      right: 24px;
    }
    &.full-width {
      left: 24px;
      width: calc(100% - 48px);
    }
  }

  //new changes 5/6/2021 by syafiq ali
  &.fix-responsive {
    @media screen and (min-width: 600px) {
      box-shadow: none;
      position: absolute;
      width: 100%;
      right: 0;
      top: 0;
    }
  }

  .first-btn {
    padding: 0px;
    padding-right: 8px;
    color: #fff;
    border-radius: 0;
    .MuiSvgIcon-root {
      background: #fff;
      color: #ff9800;
      // background: #78a9ff;
      // border-radius: 0px;
      border-radius: 3px; //latest update
      padding: 2px;
    }
  }
  .second-btn {
    margin-left: 8px;
    // padding-top: 12px;
    padding-top: 3px;
    padding-bottom: 4px;
    .MuiIconButton-root {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      padding: 0px;
      background: #fff;
      color: #212121;
    }
    // color: #001088; //here
    .MuiSvgIcon-root {
      font-size: 20px;
    }
  }
  .MuiToolbar-root {
    flex-wrap: wrap;
    min-height: unset;
    padding: 8px 10px;
    .domain-container {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #fff;
      padding-bottom: 6px;
      align-items: center;
      .domain-session {
        flex: 1;
        .authorizer {
          @extend .text-overflow;
          font-size: 10px;
          color: #fff;
          font-weight: 600;
          line-height: 8px;
          padding-top: 2px;
        }
        .domain {
          @extend .fs-12, .fw-600, .text-overflow, .m-t-2;
          color: #212121;
          line-height: 16px;
          // display: flex;
        }
      }
      .currency {
        @extend .fs-10;
        line-height: 10px;
        color: #212121;
        font-weight: 600;
      }
    }
    .domain-new-container {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #ff9800;
      padding-bottom: 6px;
      align-items: center;

      .domain-session {
        background-color: #e0e7ff;
        border-radius: 3px;
        flex: 1;
        display: flex;
        padding: 5px;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
          0px 4px 5px 0px rgba(0, 0, 0, 0.14),
          0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        cursor: pointer;
        .authorizer {
          @extend .text-overflow;
          font-size: 10px;
          color: #fff;
          line-height: 8px;
          padding-top: 2px;
        }
        .domain {
          @extend .fs-15, .fw-600, .text-overflow, .m-t-2;
          // color: #ffb11f;
          color: #001088; // latest update
          line-height: 16px;
          flex: 1;
          // display: flex;
        }
      }
      .currency {
        @extend .fs-10;
        line-height: 10px;
        color: #ffb11f;
        font-weight: 600;
      }
    }
  }

  .MuiBreadcrumbs-ol {
    @extend .overflow-hidden;
    // line-height: 8px;
    padding-top: 2px;
    margin-right: 2px;
    display: inline-flex;
    flex-wrap: initial;
    .MuiBreadcrumbs-li {
      @extend .text-overflow;
    }
    .MuiBreadcrumbs-separator {
      margin: 0px 2px;
      color: #212121;
      font-size: 9px;
      line-height: 1px;
      padding: 12px 0px 5px 0px;
    }
    .breadcrumb-title {
      font-size: 10px;
      font-weight: 500;
      line-height: 16px;
      color: #212121;
    }
    .breadcrumb-title-right {
      font-size: 10px;
      color: #212121;
      font-weight: 600;
      line-height: 16px;
    }
  }
}

.desktop-header {
  background-color: unset;
  background: url(./../../images/header-bg.svg);
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  // background: #ffffff !important;
  // background: linear-gradient(to right, #2f47ff, #2408ba);
  z-index: 2;
  .MuiButtonBase-root {
    padding: 16px;
    margin-left: -16px;
    & .MuiSvgIcon-root {
      color: #212121;
    }
  }
  .title {
    margin-top: -2px;
    color: #212121;
  }
  .MuiListItemIcon-root {
    min-width: 30px;
  }
  .MuiInputBase-input {
    padding: 0;
  }
}

.search-header {
  background: #fff;
  width: 100%;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
  &.MuiList-root {
    padding: 0px;
  }
  &.full-width {
    left: 24px;
    width: calc(100% - 48px);
  }
  .MuiListItem-root {
    padding-left: 12px;
  }
  .MuiListItemText-root {
    margin: 0px;
    display: flex;
    padding: 4px 0px;
  }
  .MuiIconButton-edgeEnd {
    padding: 8px;
    color: #212121;
    margin-right: -8px;
  }
  .MuiInputBase-root {
    width: 100%;
    .MuiInputBase-input {
      padding: 0px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .search-indicator {
    min-width: unset;
    margin-right: 10px;
  }
  .search-field {
    position: absolute;
    width: 100%;
    background: #fff;
    margin-top: -42px;
    &.hide {
      display: none;
    }
  }
  .close-search {
    margin-top: -44px;
    &.hide {
      display: none;
    }
  }
}

.search-filter {
  display: flex;
  .search-header {
    width: 100%;
  }
  .filter-dropdown {
    flex: 1;
    padding-left: 8px;
    .search-header {
      width: 100%;
      height: 100%;
    }
  }
}

.header-root {
  width: 100%;
  background-color: #ffffff;
  display: 'flex';
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  .main-header .MuiSvgIcon-root {
    color: #ffffff;
    width: 30px;
    background: #7e8dff;
    min-height: 28px;
    border-radius: 0;
  }
}

.action-header {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  background: #e5edff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05),
    0px 0px 1px 0px rgba(0, 0, 0, 0.05), 0px 3px 4px 0px rgba(0, 0, 0, 0.05);
  .action-btn {
    color: white;
    font-size: 12px;
    font-weight: 600;
  }
}

.mobile-header .domain-container .domain-session {
  flex: 1 1;
  overflow: hidden;
  margin-right: 5px;
}

nav.breadcrumbs {
  overflow: hidden;
  margin: -5px 0 -6px;
  flex: 1;
}

nav.breadcrumbs-right {
  overflow: hidden;
  margin: -5px 0 -6px;
  // width: 50%;
  text-align: right;
  color: #212121;
  .MuiBreadcrumbs-separator {
    margin: 0px 2px;
    color: #212121 !important;
    font-size: 10px;
    line-height: 1px;
    padding: 12px 0px 5px 0px;
  }
}
.transform-180 {
  transform: rotate(180deg);
}

.header-dropdown {
  width: 80%;
  max-width: 80%;
  padding: 6px;
}

// new Header changes (25/3/2021)
.dynamic-subheader.avatar {
  flex-direction: row;
}
.dynamic-subheader {
  @media screen and (min-width: 600px) {
    // width: calc(100% - 288px);
  }
  width: 100%;
  display: flex;
  z-index: 1200;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;
  padding: 8px 12px;
  position: absolute !important;
  background: #faf2e8;
  &.full-width {
    left: 24px;
    width: calc(100% - 48px);
  }
  &.single {
    height: 36px;
  }
  &.multiple {
    height: 53px;
  }
  .avatar-content {
    width: 60px;
    padding-right: 8px;
    .MuiAvatar-root {
      width: 35px;
      height: 35px;
      text-align: center;
      margin: auto;
      margin-bottom: 4px;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05),
        0px 0px 1px 0px rgba(0, 0, 0, 0.05), 0px 3px 4px 0px rgba(0, 0, 0, 0.05);
    }
    //new changes 5/6/2021 by syafiq ali
    &.fix-subheader-content {
      width: 100%;
      top: 69px;
    }
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 157px;

      //new changes 5/6/2021 by syafiq ali
      &.fix-subheader-content {
        top: 157px !important;
        position: absolute !important;
      }
    }

    .dynamic-content {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #ffffff;
      align-items: center;
      .session {
        flex: 1;
        display: flex;
        margin-bottom: 2px;
        margin-top: 2px;
        .title {
          @extend .fs-12, .fw-600, .text-overflow;
          color: #212121;
          line-height: 16px;
          // display: flex;
        }
        .rightText {
          @extend .fs-12, .fw-600, .text-overflow;
          line-height: 10px;
          color: #212121;
          font-weight: 600;
        }
      }
    }
    .infoline-content {
      display: flex;
      width: 100%;
      // margin-bottom: 2px;
      margin-top: 4px;
      .infoline {
        @extend .fs-10, .fw-500;
        color: #212121;
        line-height: 16px;
        width: 100%;
      }
    }
  }

  // new changes 10/6/2021 by syafiq ali
}
.search-filter.with-dropdown-filter {
  position: fixed;
  width: 98%;
  z-index: 100;
  padding: 4px 3px;
  background: whitesmoke;
  &.with-subheader {
    top: 106px;
    width: 100%;
    @media screen and (min-width: 600px) {
      top: 200px;
      width: calc(100% - 48px);
      padding: 0;
      transition: width 0.2s;
      right: 24px;
    }
  }
  @media screen and (min-width: 600px) {
    top: 166px;
    width: calc(100% - 48px);
    padding: 0;
    transition: width 0.2s;
    right: 24px;
  }
}
.search-filter.with-dropdown-filter-fw {
  position: fixed;
  width: 98%;
  z-index: 100;
  padding: 4px 3px;
  background: whitesmoke;
  &.with-subheader {
    top: 106px;
    width: 98%;
    @media screen and (min-width: 600px) {
      top: 200px;
      width: calc(100% - 288px);
      padding: 0;
      transition: width 0.2s;
      right: 24px;
    }
  }
  @media screen and (min-width: 600px) {
    top: 166px;
    width: calc(100% - 288px);
    padding: 0;
    transition: width 0.2s;
    right: 24px;
  }
}

.fix-subheader {
  .MuiButtonBase-root.MuiIconButton-root.right-btn {
    padding: 0px;
    margin-left: 8px;
    color: #212121;
    .MuiSvgIcon-root {
      font-size: 18px;
    }
  }
}
